import React from 'react'
import config from '../utils/siteConfig'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'

const Text = styled.p`
  text-align: left;
  line-height: 1.6;
  a {
    color: #121212;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title lang={config.language}>Termos de Uso - Cashback Ranking</title>

      <meta charset="utf-8"></meta>
      <link rel="canonical" href="https://www.cashbackranking.com.br/terms-of-use/" />
      <meta name="description" content="Termos de Uso - Cashback Ranking" />
      <meta httpEquiv="content-language" content="pt-br" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
      <meta name="HandheldFriendly" content="True"></meta>
      <meta name="MobileOptimized" content="320"></meta>
    </Helmet>

    <Container>
      <PageTitle>Termos de Uso</PageTitle>
      <Text>
        Ao acessar ao site Cashback Ranking, concorda em cumprir estes termos de
        serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é
        responsável pelo cumprimento de todas as leis locais aplicáveis. Se você
        não concordar com algum desses termos, está proibido de usar ou acessar
        este site. Os materiais contidos neste site são protegidos pelas leis de
        direitos autorais e marcas comerciais aplicáveis.
      </Text>
      <br />
      <Text>
        O Cashback Ranking não analisou todos os sites vinculados ao seu site e
        não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de
        qualquer link não implica endosso por Cashback Ranking do site. O uso de
        qualquer site vinculado é por conta e risco do usuário.
      </Text>
      <br />
      <Text>
        Modificações - O Cashback Ranking pode revisar estes termos de serviço
        do site a qualquer momento, sem aviso prévio. Ao usar este site, você
        concorda em ficar vinculado à versão atual desses termos de serviço.
      </Text>
      <br />
      <Text>
        Lei aplicável - Estes termos e condições são regidos e interpretados de
        acordo com as leis do Cashback Ranking e você se submete
        irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou
        localidade.
      </Text>
      <br />
    </Container>
  </Layout>
)

export default NotFoundPage
